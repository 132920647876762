
const PRODUCTION_NAME_DEV = 'inr188';
const PRODUCTION_NAME_DISPLAY = 'LUCKY66';
const WIN1_TG_URL = 'https://t.me/+6xLC3Trva-9jNjI1';
const WIN2_TG_URL = 'https://t.me/+hP1J4PQ_gi82Nzk1';
const WIN3_TG_URL = 'https://t.me/+HBipIwUSAzo4ZTdl';
const WIN5_TG_URL = 'https://t.me/+IfPPouAAUxozZTRl';
const MAIN_URL = 'https://www.ingame.cc';
const ENV = "production";

 export {
PRODUCTION_NAME_DEV,
PRODUCTION_NAME_DISPLAY,
WIN1_TG_URL,
WIN2_TG_URL,
WIN3_TG_URL,
WIN5_TG_URL,
MAIN_URL,
ENV,
}